import { mixins, Options } from "vue-class-component";
import { plainToClass }    from "class-transformer";
import { FilterMatchMode } from "primevue/api";
import Menu                from "primevue/menu";

import { OrdersRoutesEnum } from "../../router";

import {
  AlertDialog, CustomerAutocomplete, PageHeader, ProgressDialog, SupplierAutocomplete, TakerAutocomplete,
  ZoneAutocomplete,
} from "@/components"

import { TableMixin } from "@sharedComponents";

import { OrderAssignmentDialog, OrderPaymentCell, OrderShiftCell } from '../../components'

import { Order }            from "@/model/Order";
import { Entity, UserType } from "@/model/Entity";
import { OrderStatusEnum }  from "@/model/enums/OrderStatusEnum";

import { SessionStorageEnum } from "@/utils/SessionStorageEnum";

import { AssignmentPayload } from "../../components/OrderAssignmentDialog/OrderAssignmentDialog";

import OrdersExportBtn from "./OrdersExportBtn/OrdersExportBtn.vue";

import OrderTable, { AssignedOptionEnum, FromTo, OrdersAdvFilters } from "../../mixins/OrderTable";
import OrderActionsMixin                                            from "../../mixins/OrderActions";
import moment                                                       from "moment";
import { TakersRoutesEnum }                                         from "@/modules/takers/router";
import { SuppliersRoutesEnum }                                      from "@/modules/suppliers/router";
import { CustomersRoutesEnum }                                      from "@/modules/customers/router";
import { ordersService } from "@services/orders.service";

@Options( {
  components: {
    AlertDialog,
    CustomerAutocomplete,
    PageHeader,
    ProgressDialog,
    SupplierAutocomplete,
    TakerAutocomplete,
    ZoneAutocomplete,

    OrderAssignmentDialog,
    OrdersExportBtn,
    OrderPaymentCell,
    OrderShiftCell
  }
})
export default class OrdersPage extends mixins(OrderTable, OrderActionsMixin) {
  showAlertMessage: boolean = false;

  /**
   * Alert message generico.
   */
  alertMessage    : string  = "";

  /**
   * Selezione multipla dalla tabella
   */
  selectedOrders: Order[] = [];

  /**
   * Mostra/Nasconde un dialog centrale con la barra di progresso
   * Per le operazioni più lunge come il cambio stato di molteplici ordini
   */
  displayProgress: boolean = false;

  /**
   * FUNZIONE assegnata all'evento "confirm" del Dialog per l'assegnazione
   * di un ordine. In base all'assegnazione singola o mutipla.
   */
  confirmAssignmentCallback: Function = null;

  get takerType() {
    return UserType.TAKER;
  }

  get supplierType() {
    return UserType.SUPPLIER;
  }

  get newOrderRoute() {
    return {
      name: OrdersRoutesEnum.ORDERS_NEW_ZONE,
    };
  }

  get customerRoute() {
    return CustomersRoutesEnum.CUSTOMERS_DETAIL;
  }

  /**
   * Lista di opzioni per il cambio stato di molteplici ordini
   */
  get changeStatusOption() {
    return (<any[]>this.statusOptions)
        .filter( x =>
            ![
              OrderStatusEnum.CREATO,
              OrderStatusEnum.ASSEGNATO
            ].includes( x.value )
        )
        .map( status => {
          status.command = () => this.setStatusMassively( status.value )
          return status;
        } )
  }

  get table() {
    return this.$refs.dataTable as TableMixin;
  }

  get shiftOptions() {
    return this.$store.getters?.timeslotsLabelValue;
  }

  /**
   * Lista di opzioni per il filtraggio per stato degli ordini
   */
  get statusOptions() {
    return this.orderStatusOptions.map( o => ({
      label: o.statusLabel,
      value: o.value
    }) )
  }

  getEntityDetailsRoute( { type, id }: Entity ) {
    return {
      name  : type === UserType.SUPPLIER
          ? SuppliersRoutesEnum.SUPPLIERS_DETAIL
          : TakersRoutesEnum.TAKERS_DETAIL,
      params: {
        supplierId: id,
        takerId   : id
      }
    }
  }

  /**
   * Apre il menu per il cambio stato massivo
   */
  toggleMassiveStatusChangeMenu( event: Event ) {
    (this.$refs.osm_massively as Menu).toggle( event );
  }

  /**
   * Apre il menu per le azioni sul singolo ordine della riga
   */
  toggleTableActions( event: Event, order: Order ) {
    this.selectedOrder = order;
    (this.$refs.osm as Menu).toggle( event );
  }

  /**
   * Apre il dialog per l'assegnazione
   * Imposta la callback per l'assegnazione multipla
   */
  startMassiveAssignment() {
    const zoneIds = this.selectedOrders.reduce( ( a, x ) => {
      if (!a.includes( x.zone_id )) {
        a.push( x.zone_id );
      }

      return a;
    }, [] );

    if (zoneIds?.length > 1) {
      this.alertMessage =
          "Gli ordini selezionati non appartengono alla stessa zona";

      this.showAlertMessage = true;
      return;
    }

    this.zoneIdParam = zoneIds?.length === 1
        ? zoneIds[0]
        : null;

    this.showAssignmentDialog      = true;
    this.confirmAssignmentCallback = this.onMassiveAssignmentConfirm;
  }

  async onMassiveAssignmentConfirm( event: AssignmentPayload ) {
    while (this.selectedOrders?.length > 0) {
      const orders = this.selectedOrders.splice( 0, 20 );

      const results = await Promise.allSettled(
          orders.map( order => {
            return this.assignOrder( event, order );
          } )
      );
    }

    this.showAssignmentDialog = false;

    this.$successMessage("Ordini assegnati");

    this.resetSelections();

    this.table.applyFilter();

    this.confirmAssignmentCallback = this.onAssignmentConfirm;
  }

  async setStatusMassively(newStatus: OrderStatusEnum) {

    const statuses = this.selectedOrders.reduce( (a,x) => {
      if (!a.includes(x.status)){
        a.push(x.status);
      }
      return a;
    }, []);

    let response = true;
    if (statuses?.length > 1) {
      response = await this.$confirmMessage(
        "Non tutti gli ordini selezionati hanno lo stesso stato. \n \
        Procedere con il cambio stato?",
        "Attenzione"
      )
    }

    if (response) {
      const action = this.orderStatusOptions
                         .find( x => x.value === newStatus ).command;

      this.displayProgress = true;

      while (this.selectedOrders?.length > 0) {
        const orders = this.selectedOrders.splice(0, 20);

        const results = await Promise.allSettled(
          orders.map( order => {
            this.selectedOrder = order;
            return action();
          })
        );
      }

      setTimeout(() => {
        this.displayProgress = false;
      }, 500);

      this.$successMessage("Stato ordini aggiornato");

      this.table.applyFilter();

      this.selectedOrders = [];
    }

  }

  onExportError(error:string){
    this.alertMessage = error;
    this.showAlertMessage = true;
  }

  /**
   * Assegnazione di un singolo ordine
   */
  async onAssignmentConfirm(event: AssignmentPayload, ) {
    await this.$waitFor(
      async () => {
        await this.assignOrder(event, this.selectedOrder);

        this.$successMessage("Ordine aggiornato");

        this.table.applyFilter();
      }
    );

    this.selectedOrder = null;
    this.showAssignmentDialog = false;

  }

  openCalendar(event){
    (this.$refs as any).op?.toggle(event);
  }

  onReset() {
    this.advFilters = new OrdersAdvFilters();
    this.resetSelections();
    this.saveAdvancedFilters();
    this.updateFilters();
  }

  updateFilters() {
    const table = (this.$refs.dataTable as TableMixin);
    if (table?.filters) {
      const filters = table.filters;

      const {
              supplier,
              taker,
              customer,
              zone,
              assignedOption,
              paymentStatus
            } = this.advFilters;

      // Period
      if (this.advFilters.from && this.advFilters.to) {
        filters.order_date.value = [
          moment( this.advFilters.from ).format( 'yyyy-MM-DD' ),
          moment( this.advFilters.to ).format( 'yyyy-MM-DD' )
        ];

        filters.order_date.matchMode = FilterMatchMode.BETWEEN;

      } else if (this.advFilters.from) {
        filters.order_date.value     = this.advFilters.from;
        filters.order_date.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }

      // Customer
      filters.customer_id.value =  customer?.id;

      // Entity
      filters.entity_id.value    = supplier?.id || taker?.id;

      // Zone
      filters.zone_id.value = zone?.id;

      // EntityType
      if (assignedOption) {
        // // Clear supplier and taker filter on UI
        this.advFilters.supplier = null;
        this.advFilters.taker    = null;


        switch (assignedOption) {
          case AssignedOptionEnum.INTERNAL:
            // Internal means TAKER
            filters.entity_type.value  = UserType.TAKER;
            // Not a specific taker
            filters.entity_id.value    = null;
            // Not consider null value
            filters.entity_id.nullable = false;
            break;

          case AssignedOptionEnum.INTERNAL_NOT_ASSIGNED:
            // Internal means TAKER
            filters.entity_type.value  = UserType.TAKER;
            // Cleare supplier and taker filter on Table
            filters.entity_id.value    = null;
            // Have to consider null value
            filters.entity_id.nullable = true;
            break;

          case AssignedOptionEnum.EXTERNAL:
            // External means SUPPLIER
            filters.entity_type.value  = UserType.SUPPLIER;
            // Cleare supplier and taker filter on Table
            filters.entity_id.value    = null;
            // Not consider null value
            filters.entity_id.nullable = false;
            break;
        }
      } else {
        filters.entity_type.value  = null;
        filters.entity_id.nullable = false;
      }

      // Payment Status & Type
      if (paymentStatus) {
        const {
          payment_status,
          payment_type
        } = paymentStatus;

        filters.payment_status.value = payment_status;
        filters.payment_type.value = payment_type;
      }

    }

    //Session storage
    this.saveAdvancedFilters();

    // Refresh
    (this.$refs.dataTable as TableMixin)?.applyFilter();

    this.resetSelections()
  }

  hideCalendar() {
    (this.$refs as any).op?.hide();
  }

  get internal() { return AssignedOptionEnum.INTERNAL ; }
  get external() { return AssignedOptionEnum.EXTERNAL ; }
  get assigned() { return AssignedOptionEnum.INTERNAL_NOT_ASSIGNED ; }

  assignedTo(to: AssignedOptionEnum) {
    this.advFilters.assignedOption =
        this.advFilters.assignedOption === to
            ? null
            : to;

    this.updateFilters();
  }

  private saveAdvancedFilters() {
    sessionStorage.setItem(
        SessionStorageEnum.ORDERS_ADVANCED_FILTERS,
        JSON.stringify( this.advFilters )
    );
  }

  private restoreAdvancedFilters() {
    const fromStorage = JSON.parse(
      sessionStorage.getItem(SessionStorageEnum.ORDERS_ADVANCED_FILTERS)
    );

    this.advFilters = fromStorage
        ? plainToClass( OrdersAdvFilters, fromStorage )
        : this.advFilters;

    if (this.advFilters?.from_to) {
      this.advFilters.from_to =
          this.advFilters?.from_to?.map( x => new Date( x ) ) as FromTo;
    }
  }

  private resetSelections() {
    this.selectedOrder = null;
    this.selectedOrders = [];
  }

  private initFilter() {

    this.filters = {
      id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      customer_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      entity_type: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      entity_id: {
        value    : null,
        matchMode: FilterMatchMode.EQUALS
      },

      order_date: {
        value: [this.advFilters.from, this.advFilters.to],
        matchMode: FilterMatchMode.BETWEEN
      },

      zone_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      shift_id: {
        value    : null,
        matchMode: FilterMatchMode.IN
      },

      status: {
        value    : null,
        matchMode: FilterMatchMode.IN
      },

      // Filtro per codice ordine
      external_client_code: {
        value    : null,
        matchMode: FilterMatchMode.CONTAINS
      },

      // Filtro per indirizzo di consegna
      dropoff_full_address: {
        value    : null,
        matchMode: FilterMatchMode.CONTAINS
      },

      payment_status: {
        value    : null,
        matchMode: FilterMatchMode.EQUALS
      },

      payment_type: {
        value    : null,
        matchMode: FilterMatchMode.EQUALS
      },

      dropoff_cap: {
        value    : null,
        matchMode: FilterMatchMode.EQUALS
      },
    };
  }

  responsiveLayout: "scroll" | "stack" = "stack";

  private loadShifts() {
    return this.$store.dispatch( 'loadShifts' );
  }

  created() {
    this.loadShifts();

    this.initFilter();
    this.restoreAdvancedFilters();

    this.confirmAssignmentCallback = this.onAssignmentConfirm;

    this.responsiveLayout = innerWidth >= 768 ? "scroll" : "stack";

    const mql = window.matchMedia('(max-width: 768px)');

    mql.onchange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        this.responsiveLayout = "stack";
      }else{
        this.responsiveLayout = "scroll";
      }
    }
  }

  mounted() {
    this.resetSelections()
  }

  onDeleteSelected() {

    this.$confirmMessage(this.$t('orders.delete_confirm'))
      .then(r => {
        if (r) {
          this.$waitFor(
            async () => {

              for(const so of this.selectedOrders) {
                await ordersService.remove(so);
              }
              
              this.resetSelections();
              this.table.applyFilter();

              this.$successMessage("Eliminazione avvenuta con successo")
            },
            "Eliminazione non riuscita"
          )
        }
      })

  }

}
